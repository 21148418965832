import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, Select, MenuItem, ListItemText, ListItem, Divider, InputLabel, InputAdornment, Input } from '@material-ui/core';

const styles = theme => ({
    dialog: {
        margin: 'auto'
    },
    dialogPaper: {
        width: '40%'
    },
    dialogActions: {
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
    listItemText: {
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    }
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

class TransactionForm extends Component {
    state = {
        transaction: {}
    }

    initialState() {
        const { account } = this.props;
        
        this.setState({
            transaction: {
                from: account
            }
        })
    }

    componentDidMount() {
        this.initialState()
    }

    componentDidValidate() {
        const { transaction } = this.state;

        return (transaction && !!transaction.from && !!transaction.to && !!transaction.amount)
    }

    handleChange = (e) => {
        const { id, name, value } = e.target;
        const { transaction } = this.state;

        transaction[id || name] = value
        this.setState({ transaction })
    }

    handleAccountChange = e => {
        const { name, value } = e.target;
        const { accounts } = this.props;
        const { transaction } = this.state;
        const account = accounts.find(account => account.id === value)

        if (account) {
            transaction[name] = account
            this.setState({ transaction })  
        }
    }

    handleSave = e => {
        const { onSave } = this.props;
        const { transaction } = this.state;
        
        e.preventDefault()
        if (!this.componentDidValidate()) return
        onSave(transaction)
    }

    render() {
        const { classes, open, onClose, account, accounts } = this.props;
        const { transaction } = this.state;

        return (
            <Dialog
                classes={{
                    root: classes.dialog,
                    paper: classes.dialogPaper
                }}
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" onSubmit={this.handleSave}>
                    <DialogTitle>
                        New Transaction
                    </DialogTitle>
                    <DialogContent>
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            InputProps={{
                                readOnly: true
                            }}
                            autoComplete="off"
                            autoFocus 
                            margin="dense" 
                            id="from" 
                            label="From"
                            defaultValue={`${account.bank.routing} ${account.number}`} 
                            fullWidth
                        />
                        <FormControl className={classes.formControl}
                            margin="dense" 
                            fullWidth
                        >
                            <InputLabel required={false} htmlFor="to">To</InputLabel>
                            <Select
                                required={true}
                                value={(transaction.to) ? transaction.to.id : ''}
                                className={classes.select}
                                inputProps={{
                                    name: 'to',
                                    id: 'to',
                                }}
                                onChange={this.handleAccountChange}
                            >
                                {(accounts.length > 0) ? 
                                    accounts.map((acc, index) => (acc.id !== account.id) ?
                                        <MenuItem key={index} value={acc.id}>
                                            <ListItemText
                                                primary={`${acc.customer.fullName} (${acc.bank.name})`} 
                                                secondary={`${acc.bank.routing} ${acc.number}`} 
                                            />
                                        </MenuItem> : null
                                    )
                                : (
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl 
                            margin="dense" 
                            fullWidth
                        >
                            <InputLabel htmlFor="amount">Amount</InputLabel>
                            <Input
                                id="amount"
                                type="number"
                                inputProps={{
                                    step: ".01"
                                }}
                                onChange={this.handleChange}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </FormControl>
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="dense" 
                            id="description" 
                            label="Description"
                            onChange={this.handleChange}
                            fullWidth
                        /> 
                        <DialogActions>
                            <Button color="primary" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Send
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        )
    }
}

TransactionForm.propTypes = {
    classes: PropTypes.object.isRequired,
    isPending: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    account: PropTypes.object,
    transaction: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}

export default withStyles(styles)(TransactionForm)