import { connect } from 'react-redux';
import BanksForm from 'components/BanksForm';
import { logout, fetchBankCustomer, fetchBankAccount } from 'actions';

const mapStateToProps = state => ({
    inProgress: state.account.inProgress,
    user: state.user.data,
    customer: state.customer.data,
    error: state.customer.error
});

const mapDispatchToProps = (dispatch, props) => ({
    onShow: () => dispatch(fetchBankCustomer()),
    onLogout: () => {
        const { history } = props;
        
        return dispatch(logout(history));
    },
	onSubmit: (bank, customer) => {
        const { history } = props;
        
        return dispatch(fetchBankAccount(bank, customer, history));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BanksForm);