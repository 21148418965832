import axios from 'axios';

export const webApiRequest = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    // timeout: 1000
})

export const bfiApiRequest = axios.create({
    baseURL: process.env.REACT_APP_BFID_API_URL,
    headers: {
        'Content-Type': 'application/json', 
        'X-API-Key': `apikey ${process.env.REACT_APP_BFID_API_KEY}` 
    },
    // withCredentials: true,
    // timeout: 1000
})

bfiApiRequest.interceptors.response.use(response => response, error => {
    if (error.response) {
        return Promise.reject(error.response.data.errors ? { message: error.response.data.errors[0].msg } : error.response.data) 
    } 
    else if (error.request) {
        return Promise.reject(error.request ? { message: error.request.responseText } : { message: 'Request error' })
    } 
    else {
        return Promise.reject(error)
    }
})