import React from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    cover: {
        display: 'inline-block',
        height: '100%',
        width: '50%',
        backgroundImage: 'linear-gradient(to right top, #1d3557, #135378, #007396, #0095ae, #00b7bf)'
    },
    img: {
        background: 'url(/images/bg.png) bottom no-repeat;',
        width: '100%',
        height: '100%'
    },
    details: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        height: '100%',
        width: '50%',
        minWidth: theme.spacing.unit * 41
    },
    loopthy: {
        width: 20,
        height: 20,
        background: 'url(/images/icon.png) center center',
        backgroundSize: 'cover'
    },
    copyRight: {
        position: 'absolute',
        bottom: 0,
        paddingBottom: theme.spacing.unit * 3,
        display: 'inline-flex'
    },
    link: {
        color:  theme.palette.text.secondary
    }      
})

const BrandPaper = ({ children, classes }) => (
    <div className={classes.root}>
        <div className={classes.cover}>
            <div className={classes.img}></div>
        </div>
        <div className={classes.details}>
            {children}
            <Typography className={classes.copyRight} color="textSecondary">
                <span className={classes.loopthy}></span>
                <span>&nbsp;<a className={classes.link} href='https://www.loopthy.com' target='_blank'>Loopthy Corp</a></span>
                &nbsp;© 2019. All rights reserved.
            </Typography>
        </div>
    </div>    
)

BrandPaper.defaultProps = {
}

BrandPaper.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(BrandPaper)