import { connect } from 'react-redux';
import RegisterForm from 'components/RegisterForm';
import { register } from 'actions';

const mapStateToProps = state => ({
    inProgress: state.user.inProgress,
    user: state.user.data,
    error: state.user.error
});

const mapDispatchToProps = (dispatch, props) => ({
	onRegister: (firstName, lastName, email, phone, password) => {
        const { history } = props;
        
        dispatch(register(firstName, lastName, email, phone, password, history));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegisterForm);