import { connect } from 'react-redux';
import { fetchMessages, deleteMessage } from 'actions';

import Messages from 'components/Messages';

const mapStateToProps = (state) => {
    return {
        inProgress: state.messages.inProgress,
        account: state.account.data,
        messages: state.messages.data
    }
};

const mapDispatchToProps = dispatch => ({
    onRefresh: account => dispatch(fetchMessages(account)),
    onDelete: messages => dispatch(deleteMessage(messages))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Messages);