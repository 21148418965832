export const VERIFICATION_ITERATIONS = 30
export const VERIFICATION_INTERVAL = 3000 //ms

export const CUSTOMER_BANK_STATUS_ACTIVE = 40
export const CUSTOMER_BANK_STATUS_INACTIVE = 41
export const VERIFICATION_AUTH_STATUS_PENDING = 20
export const VERIFICATION_AUTH_STATUS_APPROVED = 21
export const VERIFICATION_AUTH_STATUS_DENIED = 22
export const VERIFICATION_AUTH_STATUS_REJECTED = 23
export const VERIFICATION_TRANS_STATUS_PENDING = 10
export const VERIFICATION_TRANS_STATUS_APPROVED = 11
export const VERIFICATION_TRANS_STATUS_DENIED = 12
export const VERIFICATION_TRANS_STATUS_REJECTED = 13