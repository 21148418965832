import React, { Component } from 'react';
import {  Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const styles = theme => ({
    flex: {
        flex: 1
    },
    form: {
        flexShrink: 0,
        width: theme.spacing.unit * 41
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing.unit * 4,
        '& > *': {
            padding: theme.spacing.unit / 2
        }
    },
    content: {
        flex: '1 0 auto',
    },
    instruction: {
        paddingTop: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    buttons: {
        marginTop: theme.spacing.unit * 5
    },
    button: {
        width: theme.spacing.unit * 19.5
    },
    submit: {
        marginLeft: theme.spacing.unit * 2
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing.unit - 4,
        marginLeft: -theme.spacing.unit - 4
    }
});

class BanksForm extends Component {

    state = { bank: null }

    componentDidMount() {
        const { onShow } = this.props

        onShow()
    }

    handleBankChange = e => {
        const { name, value } = e.target;

        if (name && value) {
            const { customer } = this.props;
            const bank = customer.banks.find(bank => bank.id === value);

            if (bank) {
                this.setState({ bank })
            }
        }
    }

    handleLogout = e => {
        const { onLogout } = this.props

        e.preventDefault()
        onLogout()
    }

    handleSubmit = e => {
        const { customer, onSubmit } = this.props
        const { bank } = this.state

        e.preventDefault()
        if (bank) {
            onSubmit(bank, customer)
        }
    }

    render() {
        const { classes, customer, inProgress } = this.props;
        const { bank } = this.state;

        return (
            <BrandPaper>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className={classes.header}>
                        <Typography component="h5" variant="h4">
                            Online Banking
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Choose a bank
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <FormControl className={classes.formControl}
                            margin="dense" 
                            fullWidth
                        >
                            <Select
                                required={true}
                                value={(bank) ? bank.id : ''}
                                className={classes.select}
                                inputProps={{
                                    name: 'bankId',
                                    id: 'bankId',
                                }}
                                onChange={this.handleBankChange}
                            >
                                {(customer && customer.banks.length > 0) ? 
                                    customer.banks.map((bank, index) =>
                                        <MenuItem key={index} value={bank.id}>{bank.name}</MenuItem>
                                    )
                                : (
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.actions}>
                        <DarkTheme>
                            <div className={classes.buttons}>
                                <Button disabled={inProgress} size="small" variant="outlined" color="primary" className={classes.button} onClick={this.handleLogout} >Logout</Button>
                                <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={`${classes.button} ${classes.submit}`}>
                                    Submit
                                    {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>   
                            </div> 
                        </DarkTheme>
                    </div>
                </form>
            </BrandPaper>
        )
    }
}

BanksForm.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    customer: PropTypes.object,
    inProgress: PropTypes.bool,
    onShow: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
};

export default withStyles(styles)(BanksForm);