import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  Link  } from 'react-router-dom';
import {  withStyles, createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
    form: {
        flexShrink: 0,
        width: theme.spacing.unit * 41
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing.unit * 4,
        '& > *': {
            padding: theme.spacing.unit / 2
        }
    },
    content: {
        flex: '1 0 auto',
    },
    textField: {
        width: theme.spacing.unit * 41
    },
    actions: {
        
    },
    inline: {
        display: 'inline-block',
        width: '100%'
    },
    link: {
        fontSize: '12px',
        display: 'block',
        textDecoration: 'none !important',
        float: 'right',
        marginTop: theme.spacing.unit * 2.5
    },
    buttons: {
        marginTop: theme.spacing.unit * 5
    },
    button: {
        width: theme.spacing.unit * 19.5
    },
    login: {
        marginLeft: theme.spacing.unit * 2
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing.unit - 4,
        marginLeft: -theme.spacing.unit - 4
    }
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

class LoginForm extends Component {
    state = {
        email: '',
        password: ''
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        
        this.setState({ [id]: value });
    }

    handleSubmit = (e) => {
        const { email, password } = this.state;
        const { onLogin } = this.props;

        e.preventDefault();
        onLogin(email, password);
    }

    render() {
        const { classes, inProgress } = this.props

        return (
            <BrandPaper>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className={classes.header}>
                        <Typography component="h5" variant="h4">
                            Online Banking
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Welcome! Please login to your account.
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            autoFocus
                            id="email"
                            label="Email"
                            className={classes.textField}
                            type="email"
                            onChange={this.handleChange}
                            margin="normal"
                            required={true}
                        />
                        <br/>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            id="password"
                            label="Password"
                            className={classes.textField}
                            type="password"
                            onChange={this.handleChange}
                            autoComplete="current-password"
                            margin="normal"
                            required={true}
                        />
                    </div>
                    <div className={classes.actions}>
                        <DarkTheme>
                            <div className={classes.inline}>
                                <Button className={classes.link} component={Link} to="/forgot">Forgot Password</Button>
                            </div>
                            <div className={classes.buttons}>
                                <Button disabled={inProgress} size="small" variant="outlined" color="primary" className={classes.button} component={Link} to="/register">Sign up</Button>
                                <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={`${classes.button} ${classes.login}`}>
                                    Login
                                    {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>   
                            </div> 
                        </DarkTheme>
                    </div>
                </form>
            </BrandPaper>
        )
    }
}

LoginForm.propTypes = {
    classes: PropTypes.object.isRequired,
    inProgress: PropTypes.bool,
    onLogin: PropTypes.func.isRequired
};

export default withStyles(styles)(LoginForm);