import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';

const theme = createMuiTheme({
    palette: {
        primary:  { main: '#2E86AB'},
        text: { secondary: '#8b8b92'}
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Work Sans',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(',')
    }
});

const MainTheme = ({ children }) => (<MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>)

export default MainTheme