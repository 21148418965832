import * as Types from 'constants/ActionTypes';
import { webApiRequest } from 'actions/request';

const DELETE_MESSAGE_SUCCESS = 'This action was successfully'

function requestMessages() {
    return {
        type: Types.REQUEST_FETCH_MESSAGES
    }
};

function responseMessagesSuccess(messages) {
    return {
        type: Types.RESPONSE_FETCH_MESSAGES_SUCCESS,
        messages,
        receivedAt: Date.now()
    }
};

function responseMessagesFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_MESSAGES_FAILURE,
        error,
        receivedAt: Date.now()
    }
}

function requestDelete() {
    return {
        type: Types.REQUEST_DELETE_MESSAGE
    }
};

function responseDeleteSuccess(json) {
    return {
        type: Types.RESPONSE_DELETE_MESSAGE_SUCCESS,
        messages: json,
        success: {
            message: DELETE_MESSAGE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDeleteFailure(error) {
    return {
        type: Types.RESPONSE_DELETE_MESSAGE_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchMessages(account) {
    return function(dispatch) {
        dispatch(requestMessages());
        return webApiRequest.get(`/accounts/${account.id}/messages`)
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseMessagesFailure(data.error));
            }
            else {
                dispatch(responseMessagesSuccess(data.result));
            }
        }, e => {
            const response = e.response;

            dispatch(responseMessagesFailure({
                message: response.statusText
            }))
        })
    }
}

export function deleteMessage(messages) {
    return function(dispatch) {
        const uri = (messages.length > 1) ? 
            `messages?ids=${messages.map(message => message.id).join(',')}` : 
            `/messages/${messages[0].id}`

        dispatch(requestDelete());

        return webApiRequest.delete(uri)
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseDeleteFailure(data.error));
            }
            else {
                dispatch(responseDeleteSuccess(data.result));
            }
        }, e => {
            const response = e.response;

            dispatch(responseDeleteFailure({
                message: response.statusText
            }))
        })
    }
};