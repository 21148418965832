import { 
    RESPONSE_LOGIN_SUCCESS,
    RESPONSE_LOGOUT_SUCCESS,
    RESPONSE_REGISTER_SUCCESS,
    RESPONSE_VERIFY_EMAIL_SUCCESS,
    RESPONSE_RESEND_SUCCESS,
    RESPONSE_FORGOT_PWD_SUCCESS,
    RESPONSE_RESET_PWD_SUCCESS,
    RESPONSE_AUTH_SUCCESS,
    RESPONSE_AUTH_FAILURE,
    RESPONSE_REGISTER_FAILURE,
    RESPONSE_VERIFY_EMAIL_FAILURE,
    RESPONSE_RESEND_FAILURE,
    RESPONSE_FORGOT_PWD_FAILURE,
    RESPONSE_RESET_PWD_FAILURE,
    RESPONSE_LOGIN_FAILURE,
    RESPONSE_LOGOUT_FAILURE,

    RESPONSE_UPDATE_ACCOUNT_SUCCESS,
    RESPONSE_UPDATE_ACCOUNT_FAILURE,
    RESPONSE_CREATE_TRANSACTION_SUCCESS,
    RESPONSE_CREATE_TRANSACTION_PENDING,
    RESPONSE_CREATE_TRANSACTION_FAILURE,
    RESPONSE_FETCH_ACCOUNT_PENDING,
    RESPONSE_FETCH_ACCOUNT_FAILURE,
    RESPONSE_DELETE_MESSAGE_SUCCESS,
    RESPONSE_DELETE_MESSAGE_FAILURE
} from 'constants/ActionTypes';

const status = (state = {
    success: null,
    error: null
}, action) => {
    switch (action.type) {
        case RESPONSE_AUTH_SUCCESS:
        case RESPONSE_LOGIN_SUCCESS:
        case RESPONSE_REGISTER_SUCCESS:
        case RESPONSE_VERIFY_EMAIL_SUCCESS:
        case RESPONSE_RESEND_SUCCESS:
        case RESPONSE_FORGOT_PWD_SUCCESS:
        case RESPONSE_RESET_PWD_SUCCESS:
        case RESPONSE_LOGOUT_SUCCESS:

        case RESPONSE_UPDATE_ACCOUNT_SUCCESS:
        case RESPONSE_CREATE_TRANSACTION_SUCCESS:
        case RESPONSE_CREATE_TRANSACTION_PENDING:
        case RESPONSE_FETCH_ACCOUNT_PENDING:
        case RESPONSE_DELETE_MESSAGE_SUCCESS:
            return Object.assign({}, state, {
                success: action.success,
                error: null
            });
        case RESPONSE_AUTH_FAILURE:
        case RESPONSE_LOGIN_FAILURE:
        case RESPONSE_LOGOUT_FAILURE:
        case RESPONSE_REGISTER_FAILURE:
        case RESPONSE_VERIFY_EMAIL_FAILURE:
        case RESPONSE_RESEND_FAILURE:
        case RESPONSE_FORGOT_PWD_FAILURE:
        case RESPONSE_RESET_PWD_FAILURE:
        
        case RESPONSE_FETCH_ACCOUNT_FAILURE:
        case RESPONSE_UPDATE_ACCOUNT_FAILURE:
        case RESPONSE_CREATE_TRANSACTION_FAILURE:
        case RESPONSE_DELETE_MESSAGE_FAILURE:
            if (action.error.code && action.error.code === 401) {
                return state;
            }
            return Object.assign({}, state, {
                success: null,
                error: action.error
            });
        default:
            return state
    }
};

export default status;