import * as Types from 'constants/ActionTypes';

const customer = (state = {
    inProgress: false,
    didInvalidate: false,
    data: null
}, action) => {
    switch (action.type) {
        case Types.REQUEST_FETCH_CUSTOMER:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_CUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.customer,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_CUSTOMER_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default customer;