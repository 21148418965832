export const REQUEST_LOGIN  = 'REQUEST_LOGIN';
export const RESPONSE_LOGIN_SUCCESS = 'RESPONSE_LOGIN_SUCCESS';
export const RESPONSE_LOGIN_FAILURE = 'RESPONSE_LOGIN_FAILURE';
export const REQUEST_LOGOUT  = 'REQUEST_LOGOUT';
export const RESPONSE_LOGOUT_SUCCESS = 'RESPONSE_LOGOUT_SUCCESS';
export const RESPONSE_LOGOUT_FAILURE = 'RESPONSE_LOGOUT_FAILURE';
export const REQUEST_REGISTER  = 'REQUEST_REGISTER';
export const RESPONSE_REGISTER_SUCCESS = 'RESPONSE_REGISTER_SUCCESS';
export const RESPONSE_REGISTER_FAILURE = 'RESPONSE_REGISTER_FAILURE';
export const REQUEST_AUTH  = 'REQUEST_AUTH';
export const RESPONSE_AUTH_SUCCESS = 'RESPONSE_AUTH_SUCCESS';
export const RESPONSE_AUTH_FAILURE = 'RESPONSE_AUTH_FAILURE';
export const REQUEST_VERIFY_EMAIL  = 'REQUEST_VERIFY_EMAIL';
export const RESPONSE_VERIFY_EMAIL_SUCCESS = 'RESPONSE_VERIFY_EMAIL_SUCCESS';
export const RESPONSE_VERIFY_EMAIL_FAILURE = 'RESPONSE_VERIFY_EMAIL_FAILURE';
export const REQUEST_RESEND  = 'REQUEST_RESEND';
export const RESPONSE_RESEND_SUCCESS = 'RESPONSE_RESEND_SUCCESS';
export const RESPONSE_RESEND_FAILURE = 'RESPONSE_RESEND_FAILURE';
export const REQUEST_FORGOT_PWD  = 'REQUEST_FORGOT_PWD';
export const RESPONSE_FORGOT_PWD_SUCCESS = 'RESPONSE_FORGOT_PWD_SUCCESS';
export const RESPONSE_FORGOT_PWD_FAILURE = 'RESPONSE_FORGOT_PWD_FAILURE';
export const REQUEST_RESET_PWD  = 'REQUEST_RESET_PWD';
export const RESPONSE_RESET_PWD_SUCCESS = 'RESPONSE_RESET_PWD_SUCCESS';
export const RESPONSE_RESET_PWD_FAILURE = 'RESPONSE_RESET_PWD_FAILURE';

export const REQUEST_FETCH_CUSTOMER  = 'REQUEST_FETCH_CUSTOMER';
export const RESPONSE_FETCH_CUSTOMER_SUCCESS = 'RESPONSE_FETCH_CUSTOMER_SUCCESS';
export const RESPONSE_FETCH_CUSTOMER_FAILURE = 'RESPONSE_FETCH_CUSTOMER_FAILURE';

export const REQUEST_FETCH_ACCOUNT  = 'REQUEST_FETCH_ACCOUNT';
export const RESPONSE_FETCH_ACCOUNT_SUCCESS = 'RESPONSE_FETCH_ACCOUNT_SUCCESS';
export const RESPONSE_FETCH_ACCOUNT_PENDING = 'RESPONSE_FETCH_ACCOUNT_PENDING';
export const RESPONSE_FETCH_ACCOUNT_FAILURE = 'RESPONSE_FETCH_ACCOUNT_FAILURE';
export const REQUEST_FETCH_ACCOUNTS  = 'REQUEST_FETCH_ACCOUNTS';
export const RESPONSE_FETCH_ACCOUNTS_SUCCESS = 'RESPONSE_FETCH_ACCOUNTS_SUCCESS';
export const RESPONSE_FETCH_ACCOUNTS_FAILURE = 'RESPONSE_FETCH_ACCOUNTS_FAILURE';
export const REQUEST_UPDATE_ACCOUNT  = 'REQUEST_UPDATE_ACCOUNT';
export const RESPONSE_UPDATE_ACCOUNT_SUCCESS = 'RESPONSE_UPDATE_ACCOUNT_SUCCESS';
export const RESPONSE_UPDATE_ACCOUNT_FAILURE = 'RESPONSE_UPDATE_ACCOUNT_FAILURE';

export const REQUEST_FETCH_TRANSACTIONS  = 'REQUEST_FETCH_TRANSACTIONS';
export const RESPONSE_FETCH_TRANSACTIONS_SUCCESS = 'RESPONSE_FETCH_TRANSACTIONS_SUCCESS';
export const RESPONSE_FETCH_TRANSACTIONS_FAILURE = 'RESPONSE_FETCH_TRANSACTIONS_FAILURE';
export const REQUEST_CREATE_TRANSACTION  = 'REQUEST_CREATE_TRANSACTION';
export const RESPONSE_CREATE_TRANSACTION_SUCCESS = 'RESPONSE_CREATE_TRANSACTION_SUCCESS';
export const RESPONSE_CREATE_TRANSACTION_PENDING = 'RESPONSE_CREATE_TRANSACTION_PENDING';
export const RESPONSE_CREATE_TRANSACTION_FAILURE = 'RESPONSE_CREATE_TRANSACTION_FAILURE';
export const REQUEST_DELETE_TRANSACTION  = 'REQUEST_DELETE_TRANSACTION';
export const RESPONSE_DELETE_TRANSACTION_SUCCESS = 'RESPONSE_DELETE_TRANSACTION_SUCCESS';
export const RESPONSE_DELETE_TRANSACTION_FAILURE = 'RESPONSE_DELETE_TRANSACTION_FAILURE';

export const REQUEST_FETCH_MESSAGES  = 'REQUEST_FETCH_MESSAGES';
export const RESPONSE_FETCH_MESSAGES_SUCCESS = 'RESPONSE_FETCH_MESSAGES_SUCCESS';
export const RESPONSE_FETCH_MESSAGES_FAILURE = 'RESPONSE_FETCH_MESSAGES_FAILURE';
export const REQUEST_DELETE_MESSAGE  = 'REQUEST_DELETE_MESSAGE';
export const RESPONSE_DELETE_MESSAGE_SUCCESS = 'RESPONSE_DELETE_MESSAGE_SUCCESS';
export const RESPONSE_DELETE_MESSAGE_FAILURE = 'RESPONSE_DELETE_MESSAGE_FAILURE';

// export const REQUEST_FETCH_PROJECTS  = 'REQUEST_FETCH_PROJECTS';
// export const RESPONSE_FETCH_PROJECTS_SUCCESS = 'RESPONSE_FETCH_PROJECTS_SUCCESS';
// export const RESPONSE_FETCH_PROJECTS_FAILURE = 'RESPONSE_FETCH_PROJECTS_FAILURE';
// export const REQUEST_FETCH_PROJECT  = 'REQUEST_FETCH_PROJECT';
// export const RESPONSE_FETCH_PROJECT_SUCCESS = 'RESPONSE_FETCH_PROJECT_SUCCESS';
// export const RESPONSE_FETCH_PROJECT_FAILURE = 'RESPONSE_FETCH_PROJECT_FAILURE';
// export const REQUEST_CREATE_PROJECT  = 'REQUEST_CREATE_PROJECT';
// export const RESPONSE_CREATE_PROJECT_SUCCESS = 'RESPONSE_CREATE_PROJECT_SUCCESS';
// export const RESPONSE_CREATE_PROJECT_FAILURE = 'RESPONSE_CREATE_PROJECT_FAILURE';
// export const REQUEST_UPDATE_PROJECT  = 'REQUEST_UPDATE_PROJECT';
// export const RESPONSE_UPDATE_PROJECT_SUCCESS = 'RESPONSE_UPDATE_PROJECT_SUCCESS';
// export const RESPONSE_UPDATE_PROJECT_FAILURE = 'RESPONSE_UPDATE_PROJECT_FAILURE';
// export const REQUEST_DELETE_PROJECT  = 'REQUEST_DELETE_PROJECT';
// export const RESPONSE_DELETE_PROJECT_SUCCESS = 'RESPONSE_DELETE_PROJECT_SUCCESS';
// export const RESPONSE_DELETE_PROJECT_FAILURE = 'RESPONSE_DELETE_PROJECT_FAILURE';

// export const REQUEST_FETCH_STAGES  = 'REQUEST_FETCH_STAGES';
// export const RESPONSE_FETCH_STAGES_SUCCESS = 'RESPONSE_FETCH_STAGES_SUCCESS';
// export const RESPONSE_FETCH_STAGES_FAILURE = 'RESPONSE_FETCH_STAGES_FAILURE';
// export const REQUEST_CREATE_STAGE  = 'REQUEST_CREATE_STAGE';
// export const RESPONSE_CREATE_STAGE_SUCCESS = 'RESPONSE_CREATE_STAGE_SUCCESS';
// export const RESPONSE_CREATE_STAGE_FAILURE = 'RESPONSE_CREATE_STAGE_FAILURE';
// export const REQUEST_UPDATE_STAGE  = 'REQUEST_UPDATE_STAGE';
// export const RESPONSE_UPDATE_STAGE_SUCCESS = 'RESPONSE_UPDATE_STAGE_SUCCESS';
// export const RESPONSE_UPDATE_STAGE_FAILURE = 'RESPONSE_UPDATE_STAGE_FAILURE';
// export const REQUEST_DELETE_STAGE  = 'REQUEST_DELETE_STAGE';
// export const RESPONSE_DELETE_STAGE_SUCCESS = 'RESPONSE_DELETE_STAGE_SUCCESS';
// export const RESPONSE_DELETE_STAGE_FAILURE = 'RESPONSE_DELETE_STAGE_FAILURE';

// export const REQUEST_FETCH_MOBILE_PROVISIONS  = 'REQUEST_FETCH_MOBILE_PROVISIONS';
// export const RESPONSE_FETCH_MOBILE_PROVISIONS_SUCCESS = 'RESPONSE_FETCH_MOBILE_PROVISIONS_SUCCESS';
// export const RESPONSE_FETCH_MOBILE_PROVISIONS_FAILURE = 'RESPONSE_FETCH_MOBILE_PROVISIONS_FAILURE';
// export const REQUEST_CREATE_MOBILE_PROVISION  = 'REQUEST_CREATE_MOBILE_PROVISION';
// export const RESPONSE_CREATE_MOBILE_PROVISION_SUCCESS = 'RESPONSE_CREATE_MOBILE_PROVISION_SUCCESS';
// export const RESPONSE_CREATE_MOBILE_PROVISION_FAILURE = 'RESPONSE_CREATE_MOBILE_PROVISION_FAILURE';
// export const REQUEST_DELETE_MOBILE_PROVISION  = 'REQUEST_DELETE_MOBILE_PROVISION';
// export const RESPONSE_DELETE_MOBILE_PROVISION_SUCCESS = 'RESPONSE_DELETE_MOBILE_PROVISION_SUCCESS';
// export const RESPONSE_DELETE_MOBILE_PROVISION_FAILURE = 'RESPONSE_DELETE_MOBILE_PROVISION_FAILURE';

// export const REQUEST_FETCH_BUILDS  = 'REQUEST_FETCH_BUILDS';
// export const RESPONSE_FETCH_BUILDS_SUCCESS = 'RESPONSE_FETCH_BUILDS_SUCCESS';
// export const RESPONSE_FETCH_BUILDS_FAILURE = 'RESPONSE_FETCH_BUILDS_FAILURE';
// export const REQUEST_CREATE_BUILD  = 'REQUEST_CREATE_BUILD';
// export const RESPONSE_CREATE_BUILD_SUCCESS = 'RESPONSE_CREATE_BUILD_SUCCESS';
// export const RESPONSE_CREATE_BUILD_FAILURE = 'RESPONSE_CREATE_BUILD_FAILURE';
// export const REQUEST_UPDATE_BUILD  = 'REQUEST_UPDATE_BUILD';
// export const RESPONSE_UPDATE_BUILD_SUCCESS = 'RESPONSE_UPDATE_BUILD_SUCCESS';
// export const RESPONSE_UPDATE_BUILD_FAILURE = 'RESPONSE_UPDATE_BUILD_FAILURE';
// export const REQUEST_DELETE_BUILD  = 'REQUEST_DELETE_BUILD';
// export const RESPONSE_DELETE_BUILD_SUCCESS = 'RESPONSE_DELETE_BUILD_SUCCESS';
// export const RESPONSE_DELETE_BUILD_FAILURE = 'RESPONSE_DELETE_BUILD_FAILURE';

// export const REQUEST_FETCH_ASSETS  = 'REQUEST_FETCH_ASSETS';
// export const RESPONSE_FETCH_ASSETS_SUCCESS = 'RESPONSE_FETCH_ASSETS_SUCCESS';
// export const RESPONSE_FETCH_ASSETS_FAILURE = 'RESPONSE_FETCH_ASSETS_FAILURE';
// export const REQUEST_DOWNLOAD_ASSETS  = 'REQUEST_DOWNLOAD_ASSETS';
// export const RESPONSE_DOWNLOAD_ASSETS_SUCCESS = 'RESPONSE_DOWNLOAD_ASSETS_SUCCESS';
// export const RESPONSE_DOWNLOAD_ASSETS_FAILURE = 'RESPONSE_DOWNLOAD_ASSETS_FAILURE';