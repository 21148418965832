import { connect } from 'react-redux';

import Layout from 'components/Layout';
import Transactions from 'containers/Transactions';
import Messages from 'containers/Messages';
// import Setting from 'containers/Setting';

import { logout, updateBankAccount, fetchBankAccountById } from 'actions';

const links = [
    { key: "transactions", to: "/activity", component: 'Transactions', icon: "folder_open", text: "Activity" },
    { key: "messages", to: "/message", component: 'Messages', icon: "inbox", text: "Messages" },
    // { key: "settings", to: "/setting", component: 'Setting', icon: "settings", text: "Settings" }
];

const containers = {
    Transactions, 
    Messages: Messages
    // Setting: Setting
};

const mapStateToProps = state => {
    return {
        links,
        containers,
        user: state.user.data,
        customer: state.customer.data,
        account: state.account.data,
    }
};

const mapDispatchToProps = (dispatch, props) => ({
    onSetupShow: account => dispatch(fetchBankAccountById(account)),
    onSetupSave: (account, override) => dispatch(updateBankAccount(account, override)),
    onLogout: () => {
        const { history } = props;
        
        dispatch(logout(history));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Layout);