import * as Types from 'constants/ActionTypes';

const transactions = (state = {
    inProgress: false,
    isPending: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.REQUEST_FETCH_TRANSACTIONS:
        case Types.REQUEST_CREATE_TRANSACTION:
        case Types.REQUEST_DELETE_TRANSACTION:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.REQUEST_DELETE_TRANSACTION:
            return Object.assign({}, state, {
                isPending: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.transactions,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_CREATE_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                isPending: false,
                didInvalidate: false,
                data: [...state.data, action.transaction],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_TRANSACTION_SUCCESS:
            action.transactions.forEach(transaction => state.data.splice(state.data.findIndex(p => (p._id === transaction._id)), 1))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_TRANSACTIONS_FAILURE:
        case Types.RESPONSE_CREATE_TRANSACTION_FAILURE:
        case Types.RESPONSE_DELETE_TRANSACTION_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                isPending: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default transactions;