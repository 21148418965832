import { connect } from 'react-redux';
import { fetchTransactions, fetchBankAccounts, createTransaction, deleteTransaction } from 'actions';

import Transactions from 'components/Transactions';

const mapStateToProps = (state) => {
    return {
        inProgress: state.transactions.inProgress,
        isPending: state.transactions.isPending,
        account: state.account.data,
        accounts: state.accounts.data,
        transactions: state.transactions.data
    }
};

const mapDispatchToProps = dispatch => ({
    onRefresh: account => Promise.all([
        dispatch(fetchTransactions(account)),
        dispatch(fetchBankAccounts())
    ]),
    onCreate: transaction => dispatch(createTransaction(transaction)),
    onDelete: transactions => dispatch(deleteTransaction(transactions))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Transactions);