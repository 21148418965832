import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import createBrowserHistory from 'history/createBrowserHistory';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { authenticate } from 'actions';
import rootReducer from 'reducers';

import MainTheme from 'components/themes/main';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const loggerMiddleware = createLogger();

// const middleware = store => next => action => {
//     console.log("Middleware triggered:", action);
//     next(action);
// }

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware, // lets us dispatch() functions
        // middleware,
        loggerMiddleware // neat middleware that logs actions
    )
);

const history = createBrowserHistory();

store.dispatch(authenticate(history))
.then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <MainTheme>
                <App/>
            </MainTheme>
        </Provider>, document.getElementById('root')
    );
})

registerServiceWorker();
