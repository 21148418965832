import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import { dateFormat } from 'helpers';
import DataBrowser from 'components/core/DataBrowser';
import TransactionForm from 'components/TransactionForm';
import ProgressMask from 'components/ProgressMask';
import { Typography } from '@material-ui/core';

import { RESPONSE_CREATE_TRANSACTION_SUCCESS, RESPONSE_CREATE_TRANSACTION_FAILURE } from 'constants/ActionTypes';

const columnsWithClasses = (classes, context) => ([
    {   id: 'createdAt', numeric: false, disablePadding: false, label: 'Date', render: (column, item) => dateFormat(item[column.id], 'MMM D, YYYY [at] h:mm A') },
    {   id: 'type', numeric: false, disablePadding: false, label: 'Type', render: (column, item) => {
        const { account } = context.props
        const value = item['from']

        return (
            value.id === account.id ? 'Debit' : 'Credit'
        )
    } },
    {   id: 'description', numeric: false, disablePadding: false, label: 'Description' },
    {   id: 'amount', width: 200, numeric: true, disablePadding: false, label: 'Amount', render: (column, item) => {
        const value = item[column.id]
        const { account } = context.props
        const from = item['from']
        const type = from.id === account.id ? 'debit' : 'credit'

        return (
            <Typography className={classes[type]}>
                {`$ ${value.toFixed(2)}`}
            </Typography>
        )
    } }
]);

const actions = scope => ([
    { id: 'delete', label: 'Delete', icon: 'delete', handleClick: scope.handleDelete }
]);

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flexGrow: 1,
        width: '100%'
    },
    avatar: {
        marginTop: theme.spacing.unit / 2 + 2,
        marginBottom: theme.spacing.unit / 2 + 2, 
    },
    succeed: {
        color: green[600],
    },
    failed: {
        color: theme.palette.error.dark,
    },
    credit: {
        color: green[600],  
    },
    debit: {
        color: red[600],  
    }
});

class Transactions extends Component {

    state = {
        open: false,
        transaction: null,
        searchCriteria: { keys: [] }
    }

    componentDidMount() {
        const { account, onRefresh } = this.props;

        onRefresh(account)
    }

    filterData = (fields, keys) => {
        keys = keys.map(key => key.toLowerCase())
        return stage => (keys.length === 0 || fields.some(field => keys.every(key =>
            (!stage[field] && key === '') ||
            (stage[field] && stage[field].toLowerCase().indexOf(key) > -1)
        )))
    }

    handleNewClick = () => {
        return new Promise(resolve => this.setState({ 
            open: true
        }, resolve))
    }

    handleRequestSearch = (criteria) => {
        this.setState({
            searchCriteria: criteria
        })
    }

    handleDelete = (event, selected) => {
        const { transactions, onDelete } = this.props;
        const selection = selected.reduce((items, id) => {
            const item = transactions.find(transaction => transaction.id === id);
            if (item) return items.concat(item)
        }, []);

        onDelete(selection);
    }

    handleClose = () => {
        this.setState({
            open: false,
            transaction: null
        })
    }

    handleSave = async transaction => {
        const { onCreate } = this.props;

        this.handleClose();
        onCreate(transaction);
    }

    render() {
        const { account, accounts,transactions, classes, inProgress, isPending } = this.props;
        const { searchCriteria, open, transaction } = this.state;

        return (
            <div className={classes.root}>
                <DataBrowser
                    title={`Account number - ${account.number}`}
                    emptyText="No transaction(s) found"
                    columns={columnsWithClasses(classes, this)}
                    actions={actions(this)}
                    idField="id"
                    data={transactions.filter(this.filterData(['description'], searchCriteria.keys))}
                    orderBy={{ property: 'createdAt', direction: 'desc' }}
                    selectOnRowClick={false}
                    onAddButtonClick={this.handleNewClick}
                    onRequestSearch={this.handleRequestSearch}
                />
                {(open === true) && (
                    <TransactionForm
                        account={account} 
                        accounts={accounts} 
                        transaction={transaction}
                        isPending={isPending}
                        open={open} 
                        onSave={this.handleSave}
                        onClose={this.handleClose}
                    />
                )}
                {(inProgress) ? (
                    <ProgressMask/>
                ) : null}
            </div>
        )
    }
}

Transactions.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    inProgress: PropTypes.bool,
    isPending: PropTypes.bool,
    account: PropTypes.object,
    accounts: PropTypes.arrayOf(
        PropTypes.object
    ),
    transactions: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    onRefresh: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles,  { withTheme: true })(Transactions))