import * as Types from 'constants/ActionTypes';

const accounts = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.REQUEST_FETCH_ACCOUNTS:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_ACCOUNTS_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.accounts,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_UPDATE_ACCOUNT_SUCCESS:
            state.data = state.data.map(account => (account.id === action.account.id ? action.account : account))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_ACCOUNTS_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default accounts;