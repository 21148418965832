import * as Types from 'constants/ActionTypes';
import { webApiRequest } from 'actions/request';

function requestCustomer() {
    return {
        type: Types.REQUEST_FETCH_CUSTOMER
    }
};

function responseCustomerSuccess(customer) {
    return {
        type: Types.RESPONSE_FETCH_CUSTOMER_SUCCESS,
        customer,
        receivedAt: Date.now()
    }
};

function responseCustomerFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_CUSTOMER_FAILURE,
        error,
        receivedAt: Date.now()
    }
}

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchBankCustomer() {
    return function(dispatch) {
        dispatch(requestCustomer());
        return webApiRequest.get('/customers')
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseCustomerFailure(data.error));
            }
            else {
                dispatch(responseCustomerSuccess(data.result));
            }
        }, e => {
            const response = e.response;

            dispatch(responseCustomerFailure({
                message: response.statusText
            }))
        })
    }
}