import * as Types from 'constants/ActionTypes';

const messages = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.REQUEST_FETCH_MESSAGES:
        case Types.REQUEST_DELETE_MESSAGE:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.messages,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_MESSAGE_SUCCESS:
                action.messages.forEach(message => state.data.splice(state.data.findIndex(p => (p.id === message.id)), 1))
                return Object.assign({}, state, {
                    inProgress: false,
                    didInvalidate: false,
                    data: [...state.data],
                    lastUpdated: action.receivedAt
                });
        case Types.RESPONSE_FETCH_MESSAGES_FAILURE:
        case Types.RESPONSE_DELETE_MESSAGE_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default messages;