import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import { dateFormat } from 'helpers';
import DataBrowser from 'components/core/DataBrowser';
import ProgressMask from 'components/ProgressMask';

const columnsWithClasses = () => ([
    {   id: 'body', numeric: false, disablePadding: false, label: 'Body' },
    {   id: 'createdAt', numeric: false, disablePadding: false, label: 'Date', render: (column, item) => dateFormat(item[column.id], 'MMM D, YYYY [at] h:mm A') }
]);

const actions = scope => ([
    { id: 'delete', label: 'Delete', icon: 'delete', handleClick: scope.handleDelete }
]);

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flexGrow: 1,
        width: '100%'
    },
    avatar: {
        marginTop: theme.spacing.unit / 2 + 2,
        marginBottom: theme.spacing.unit / 2 + 2, 
    }
});

class Messages extends Component {

    state = {
        searchCriteria: { keys: [] }
    }

    componentDidMount() {
        const { account, onRefresh } = this.props;

        onRefresh(account)
    }

    filterData = (fields, keys) => {
        keys = keys.map(key => key.toLowerCase())
        return stage => (keys.length === 0 || fields.some(field => keys.every(key => stage[field].toLowerCase().indexOf(key) > -1)))
    }

    handleRequestSearch = (criteria) => {
        this.setState({
            searchCriteria: criteria
        })
    }

    handleDelete = (event, selected) => {
        const { messages, onDelete } = this.props;
        const selection = selected.reduce((items, id) => {
            const item = messages.find(message => message.id === id);
            if (item) return items.concat(item)
        }, []);

        onDelete(selection);
    }

    render() {
        const { messages, classes, inProgress } = this.props;
        const { searchCriteria } = this.state;

        return (
            <div className={classes.root}>
                <DataBrowser
                    title="Messages"
                    emptyText="No message(s) found"
                    columns={columnsWithClasses(classes, this)}
                    actions={actions(this)}
                    idField="id"
                    data={messages.filter(this.filterData(['body'], searchCriteria.keys))}
                    orderBy={{ property: 'createdAt', direction: 'desc' }}
                    selectOnRowClick={false}
                    onRequestSearch={this.handleRequestSearch}
                />
                {(inProgress) ? (
                    <ProgressMask/>
                ) : null}
            </div>
        )
    }
}

Messages.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    inProgress: PropTypes.bool,
    account: PropTypes.object.isRequired,
    messages: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    onRefresh: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles,  { withTheme: true })(Messages))