import React, { Component } from 'react';
import {  Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
    flex: {
        flex: 1
    },
    form: {
        flexShrink: 0,
        width: theme.spacing.unit * 41
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing.unit * 4,
        '& > *': {
            padding: theme.spacing.unit / 2
        }
    },
    content: {
        flex: '1 0 auto',
    },
    instruction: {
        paddingTop: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    buttons: {
        marginTop: theme.spacing.unit * 5
    },
    button: {
        width: theme.spacing.unit * 19.5
    },
    submit: {
        marginLeft: theme.spacing.unit * 2,
        width: theme.spacing.unit * 16
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing.unit - 4,
        marginLeft: -theme.spacing.unit - 4
    }
});

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

class SecurityCodeForm extends Component {

    state = {
        isResend: false,
        isSubmit: false
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        
        this.setState({ [id]: value });
    }

    handleResend = (e) => {
        const { user, onResend } = this.props;

        e.preventDefault();
        this.setState({
            isResend: true,
            isSubmit: false
        })
        onResend(user.local.email);
    }

    handleSubmit = (e) => {
        const { code } = this.state;
        const { user, onSubmit } = this.props;

        e.preventDefault();
        this.setState({
            isResend: false,
            isSubmit: true
        })
        onSubmit(user.local.email, code);
    }

    render() {
        const { classes, inProgress } = this.props;
        const { isResend, isSubmit } = this.state;

        return (
            <BrandPaper>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className={classes.header}>
                        <Typography component="h5" variant="h4">
                            Online Banking
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Enter the code received in your email
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            id="code"
                            label="Code"
                            fullWidth={true}
                            className={classes.textField}
                            onChange={this.handleChange}
                            margin="normal"
                            required={true}
                        />
                    </div>
                    <div className={classes.actions}>
                        <DarkTheme>
                            <div className={classes.buttons}>
                                <Button disabled={inProgress} size="small" className={classes.flex} component={Link} to="/login">Cancel</Button>
                                <Button disabled={inProgress} size="small" className={classes.flex} onClick={this.handleResend}>
                                    Resend code
                                    {inProgress && isResend && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                                <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={classes.submit}>
                                    Send
                                    {inProgress && isSubmit && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </div>
                        </DarkTheme>
                    </div>
                </form>
            </BrandPaper>
            // <form className={classes.root} autoComplete="off" onSubmit={this.handleSubmit}>
            //     <Card className={classes.card}>
            //         <CardContent>
            //             <Typography gutterBottom variant="h5" component="h2">Validate Code</Typography>
            //             <div className={classes.instruction}>
            //                 <Typography color="textSecondary" component="p">Enter the code received in your email</Typography>
            //             </div>
            //             <TextField
            //                 InputLabelProps={InputLabelProps}
            //                 id="code"
            //                 label="Code"
            //                 className={classes.textField}
            //                 onChange={this.handleChange}
            //                 margin="normal"
            //                 required={true}
            //             />
            //         </CardContent>
            //         <CardActions className={classes.cardActions}>
            //             <Button disabled={inProgress} size="small" className={classes.flex} component={Link} to="/login">Cancel</Button>
            //             <Button disabled={inProgress} size="small" className={classes.flex} onClick={this.handleResend}>
            //                 Resend code
            //                 {inProgress && isResend && <CircularProgress size={24} className={classes.buttonProgress} />}
            //             </Button>
            //             <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={[classes.button, classes.flex]}>
            //                 Send
            //                 {inProgress && isSubmit && <CircularProgress size={24} className={classes.buttonProgress} />}
            //             </Button>
            //         </CardActions>
            //     </Card>
            // </form>
        )
    }
}

SecurityCodeForm.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    inProgress: PropTypes.bool,
    onResend: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default withStyles(styles)(SecurityCodeForm);