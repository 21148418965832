import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CUSTOMER_BANK_STATUS_ACTIVE, CUSTOMER_BANK_STATUS_INACTIVE } from '../../constants';
import {  withStyles  } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch, FormControlLabel, Tooltip, Icon } from '@material-ui/core';

// const STATUS_REGISTRATION_PENDING = 'Registration Pending' //TODO: THIS IS A DB ERROR SHOULD NOT RETURN THIS VALUE FROM CUSTOMER STATUS
// const STATUS_INACTIVE = 'Inactive'
const ENROLLMENT_TOOLTIP = 'Your Facial Recognition Setup is still pending. Please complete this process following the instructions in the message previously sent to you.'

const styles = theme => ({
    dialog: {
        margin: 'auto'
    },
    dialogPaper: {
        width: '40%'
    },
    dialogActions: {
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
    listItemText: {
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    }
})

class SetupForm extends Component {
    state = {
        account: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { account } = prevState
        const nextAccount = nextProps.account
        let state = null
       
        if (!account || !nextAccount || (account && nextAccount && (
            account.setup.enrolled !== nextAccount.setup.enrolled ||
            account.setup.status !== nextAccount.setup.status
        ))) {
            state = Object.assign(state || {}, { 
                account: {
                    id: nextAccount.id,
                    setup: {...nextAccount.setup}
                } 
            })
        }
        return state
    }

    componentDidMount() {
        const { account, onShow } = this.props

        onShow(account)
    }

    handleSetupChange = e => {
        const { id, name, checked } = e.target;
        let { account } = this.state

        account.setup[id || name] = checked
        this.setState({ account })
    }

    handleResend = e => {
        const { onSave } = this.props;
        const { account } = this.state;
        
        e.preventDefault()
        onSave(account, true)
    }

    handleSave = e => {
        const { onSave } = this.props;
        const { account } = this.state;
        
        e.preventDefault()
        onSave(account)
    }

    render() {
        const { classes, open, onClose } = this.props;
        const { account } = this.state;
        const allowResend = (account && 
            (account.setup.useAuthentication || account.setup.useTransaction) && 
            account.setup.enrolled && 
            account.setup.status === CUSTOMER_BANK_STATUS_INACTIVE)

        return (
            <Dialog
                classes={{
                    root: classes.dialog,
                    paper: classes.dialogPaper
                }}
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" onSubmit={this.handleSave}>
                    <DialogTitle>
                        Setup Facial Recognition&nbsp; 
                        {(allowResend && (
                            <Tooltip title={ENROLLMENT_TOOLTIP} placement='right'>
                                <Icon color="error">
                                    info_circle
                                </Icon>
                            </Tooltip>
                        ))}
                    </DialogTitle>
                    <DialogContent>
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{
                                        name: 'useAuthentication',
                                        id: 'useAuthentication',
                                    }}
                                    checked={account && account.setup.useAuthentication}
                                    onChange={this.handleSetupChange}
                                    value={true}
                                    color="primary"
                                />
                            }
                            label="Authentication"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{
                                        name: 'useTransaction',
                                        id: 'useTransaction',
                                    }}
                                    checked={account && account.setup.useTransaction}
                                    onChange={this.handleSetupChange}
                                    value={true}
                                    color="primary"
                                />
                            }
                            label="Transaction Authorization"
                        />
                        <DialogActions>
                            <Button color="primary" onClick={onClose}>
                                Cancel
                            </Button>
                            {(allowResend && (
                                <Button color="primary" onClick={this.handleResend}>
                                    Resend Code
                                </Button>
                            ))}
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        )
    }
}

SetupForm.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
    onShow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}

export default withStyles(styles)(SetupForm)