import * as Types from 'constants/ActionTypes';
import { bfiApiRequest, webApiRequest } from 'actions/request';
import { 
    VERIFICATION_ITERATIONS,
    VERIFICATION_INTERVAL,
    VERIFICATION_AUTH_STATUS_REJECTED, 
    VERIFICATION_AUTH_STATUS_DENIED, 
    VERIFICATION_AUTH_STATUS_APPROVED 
} from '../constants';

const UPDATE_ACCOUNT_SUCCESS = 'This action was successfully'

const FETCH_ACCOUNT_PENDING = 'Bank access is pending for authorization'
const FETCH_ACCOUNT_DENIED = 'Bank access was denied'
const FETCH_ACCOUNT_REJECTED = 'Bank access was rejected'
const FETCH_ACCOUNT_TIMEOUT = 'Bank access authorization timed out'

function requestAccount() {
    return {
        type: Types.REQUEST_FETCH_ACCOUNT
    }
};

function responseAccountPending(json) {
    return {
        type: Types.RESPONSE_FETCH_ACCOUNT_PENDING,
        account: json,
        success: {
            message: FETCH_ACCOUNT_PENDING
        },
        receivedAt: Date.now()
    }
};

function responseAccountSuccess(account) {
    return {
        type: Types.RESPONSE_FETCH_ACCOUNT_SUCCESS,
        account,
        receivedAt: Date.now()
    }
};

function responseAccountFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_ACCOUNT_FAILURE,
        error,
        receivedAt: Date.now()
    }
}

function requestAccounts() {
    return {
        type: Types.REQUEST_FETCH_ACCOUNTS
    }
};

function responseAccountsSuccess(accounts) {
    return {
        type: Types.RESPONSE_FETCH_ACCOUNTS_SUCCESS,
        accounts,
        receivedAt: Date.now()
    }
};

function responseAccountsFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_ACCOUNTS_FAILURE,
        error,
        receivedAt: Date.now()
    }
}

function requestUpdate() {
    return {
        type: Types.REQUEST_UPDATE_ACCOUNT
    }
};

function responseUpdateSuccess(json) {
    return {
        type: Types.RESPONSE_UPDATE_ACCOUNT_SUCCESS,
        account: json,
        success: {
            message: UPDATE_ACCOUNT_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseUpdateFailure(error) {
    return {
        type: Types.RESPONSE_UPDATE_ACCOUNT_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

function verifyAuthenticationStatus(account) {
    const { bank, customer, token } = account

    return bfiApiRequest.post('/authentications/verifyStatus', {
        bank: { code: bank.code },
        customer: { code: customer.code },
        authentication: { token }
    })
}

export function fetchBankAccount(bank, customer, history) {
    return function(dispatch) {
        dispatch(requestAccount());
        return webApiRequest.get('/accounts', {
            params: {
                bankId: bank.id,
                customerId: customer.id
            }
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseAccountFailure(data.error));
            }
            else {
                const account = data.result[0]

                if (!account.token) {
                    dispatch(responseAccountSuccess(account))
                    history.push('/activity');
                }
                else {
                    let iteration = 0
                    
                    const doAction = () => {
                        if (iteration < VERIFICATION_ITERATIONS) {
                            setTimeout(async () => {
                                iteration++
                                try {
                                    const { data } = await verifyAuthenticationStatus(account)
                                    const { statusId } = data

                                    if (statusId === VERIFICATION_AUTH_STATUS_APPROVED) {
                                        dispatch(responseAccountSuccess(account));
                                        history.push('/activity');
                                    }
                                    else if (statusId === VERIFICATION_AUTH_STATUS_DENIED) {
                                        dispatch(responseAccountFailure({
                                            message: FETCH_ACCOUNT_DENIED
                                        }))
                                    }
                                    else if (statusId === VERIFICATION_AUTH_STATUS_REJECTED) {
                                        dispatch(responseAccountFailure({
                                            message: FETCH_ACCOUNT_REJECTED
                                        }))
                                    }
                                    else {
                                        doAction()
                                    }
                                }
                                catch(error) {
                                    dispatch(responseAccountFailure(error))  
                                }
                            }, VERIFICATION_INTERVAL)
                        }
                        else {
                            dispatch(responseAccountFailure({
                                message: FETCH_ACCOUNT_TIMEOUT
                            }))    
                        }
                    }
                    doAction()
                    return dispatch(responseAccountPending(account))
                }
            }
        }, e => {
            const response = e.response;

            dispatch(responseAccountFailure({
                message: response.statusText
            }))
        })
    }
}

export function fetchBankAccountById(account) {
    return function(dispatch) {
        dispatch(requestAccount());
        return webApiRequest.get(`/accounts/${account.id}`)
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseAccountFailure(data.error));
            }
            else {
                dispatch(responseAccountSuccess(data.result));
            }
        }, e => {
            const response = e.response;

            dispatch(responseAccountFailure({
                message: response.statusText
            }))
        })
    }
}

export function fetchBankAccounts() {
    return function(dispatch) {
        dispatch(requestAccounts());
        return webApiRequest.get('/accounts')
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseAccountsFailure(data.error));
            }
            else {
                dispatch(responseAccountsSuccess(data.result));
            }
        }, e => {
            const response = e.response;

            dispatch(responseAccountsFailure({
                message: response.statusText
            }))
        })
    }
}

export function updateBankAccount(account, override) {
    return function(dispatch) {
        const { setup } = account
        
        dispatch(requestUpdate());

        return webApiRequest.put(`/accounts/${account.id}`, {
            setup,
            override
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseUpdateFailure(data.error));
            }
            else {
                dispatch(responseUpdateSuccess(data.result));
            }
        }, e => {
            const response = e.response;

            dispatch(responseUpdateFailure({
                message: response.statusText
            }))
        })
    }
};