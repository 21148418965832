import * as Types from 'constants/ActionTypes'
import { webApiRequest, bfiApiRequest } from 'actions/request'
import { 
    VERIFICATION_ITERATIONS,
    VERIFICATION_INTERVAL,
    VERIFICATION_TRANS_STATUS_REJECTED, 
    VERIFICATION_TRANS_STATUS_DENIED, 
    VERIFICATION_TRANS_STATUS_APPROVED 
} from '../constants';

const CREATE_TRANSACTION_SUCCESS = 'Transaction was created successfully'
const CREATE_TRANSACTION_PENDING = 'Transaction is pending for authorization'
const CREATE_TRANSACTION_DENIED = 'Transaction was denied'
const CREATE_TRANSACTION_REJECTED = 'Transaction was rejected'
const CREATE_TRANSACTION_TIMEOUT = 'Transaction authorization timed out'
const DELETE_TRANSACTION_SUCCESS = 'Transaction(s) was deleted successfully'

function requestFetch() {
    return {
        type: Types.REQUEST_FETCH_TRANSACTIONS
    }
}

function responseFetchSuccess(json) {
    return {
        type: Types.RESPONSE_FETCH_TRANSACTIONS_SUCCESS,
        transactions: json,
        receivedAt: Date.now()
    }
}

function responseFetchFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_TRANSACTIONS_FAILURE,
        error,
        receivedAt: Date.now()
    }
}

function requestCreate() {
    return {
        type: Types.REQUEST_CREATE_TRANSACTION
    }
}

function responseCreateSuccess(json) {
    return {
        type: Types.RESPONSE_CREATE_TRANSACTION_SUCCESS,
        transaction: json,
        success: {
            message: CREATE_TRANSACTION_SUCCESS
        },
        receivedAt: Date.now()
    }
}

function responseCreatePending(json) {
    return {
        type: Types.RESPONSE_CREATE_TRANSACTION_PENDING,
        transaction: json,
        success: {
            message: CREATE_TRANSACTION_PENDING
        },
        receivedAt: Date.now()
    }
}

function responseCreateFailure(error) {
    return {
        type: Types.RESPONSE_CREATE_TRANSACTION_FAILURE,
        error,
        receivedAt: Date.now()
    }
}

function requestDelete() {
    return {
        type: Types.REQUEST_DELETE_TRANSACTION
    }
}

function responseDeleteSuccess(json) {
    return {
        type: Types.RESPONSE_DELETE_TRANSACTION_SUCCESS,
        transactions: json,
        success: {
            message: DELETE_TRANSACTION_SUCCESS
        },
        receivedAt: Date.now()
    }
}

function responseDeleteFailure(error) {
    return {
        type: Types.RESPONSE_DELETE_TRANSACTION_FAILURE,
        error,
        receivedAt: Date.now()
    }
}

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

function verifyTransactionStatus(transaction) {
    const { token, from } = transaction
    const { bank, customer } = from

    return bfiApiRequest.post('/transactions/verifyStatus', {
        bank: { code: bank.code },
        customer: { code: customer.code },
        transaction: { token }
    })
}

export function fetchTransactions(account) {
    return function(dispatch) {
        dispatch(requestFetch())

        return webApiRequest.get(`/accounts/${account.id}/transactions`)
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseFetchFailure(data.error))
            }
            else {
                dispatch(responseFetchSuccess(data.result))
            }
        }, e => {
            const response = e.response

            dispatch(responseFetchFailure({
                message: response.statusText
            }))
        })
    }
}

export function createTransaction(transaction) {
    return function(dispatch) {
        dispatch(requestCreate())

        return webApiRequest.post('/transactions', transaction)
        .then(async response => {
            const { data } = response

            if (data.error) {
                return dispatch(responseCreateFailure(data.error))
            }
            else {
                const newTransaction = data.result

                if (newTransaction.id && newTransaction.verified) {
                    return dispatch(responseCreateSuccess(newTransaction))
                }
                else {
                    let iteration = 0
                    
                    const doAction = () => {
                        if (iteration < VERIFICATION_ITERATIONS) {
                            setTimeout(async () => {
                                iteration++
                                try {
                                    const { data } = await verifyTransactionStatus(newTransaction)
                                    const { statusId } = data

                                    if (statusId === VERIFICATION_TRANS_STATUS_APPROVED) {
                                        newTransaction.verified = true
                                        dispatch(createTransaction(newTransaction))
                                    }
                                    else if (statusId === VERIFICATION_TRANS_STATUS_DENIED) {
                                        dispatch(responseCreateFailure({
                                            message: CREATE_TRANSACTION_DENIED
                                        }))
                                    }
                                    else if (statusId === VERIFICATION_TRANS_STATUS_REJECTED) {
                                        dispatch(responseCreateFailure({
                                            message: CREATE_TRANSACTION_REJECTED
                                        }))
                                    }
                                    else {
                                        doAction()
                                    }
                                }
                                catch(error) {
                                    dispatch(responseCreateFailure(error))  
                                }
                            }, VERIFICATION_INTERVAL)
                        }
                        else {
                            dispatch(responseCreateFailure({
                                message: CREATE_TRANSACTION_TIMEOUT
                            }))    
                        }
                    }
                    doAction()
                    return dispatch(responseCreatePending(newTransaction))
                }
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
}

export function deleteTransaction(transactions) {
    return function(dispatch) {
        dispatch(requestDelete())

        return webApiRequest.delete('/transactions', transactions)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDeleteFailure(error))
            }
            else {
                dispatch(responseDeleteSuccess(data))
            }
        }, e => dispatch(responseDeleteFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
}