import { combineReducers } from 'redux';

import user from './users';
import customer from './customers';
import account from './account';
import accounts from './accounts';
import transactions from './transactions';
import messages from './messages';
import status from './status';

export default combineReducers({
    user,
    customer,
    account,
    accounts,
    transactions,
    messages,
    status
});